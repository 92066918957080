.header-buttons.MuiButtonGroup-root {
  .MuiButtonBase-root {
    svg {
      height: 16px;
      margin-left: -6px;
      margin-right: 6px;
    }
  }
}

.MuiBox-root {
  [href="/variant"] {
    order: 1;
  }
  [href="/case"] {
    order: 2;
  }
  [href="/observation"] {
    order: 3;
  }
  [href="/study"] {
    order: 4;
  }
  [href="/about"] {
    order: 10;
  }

  .tab-report {
    order: 90;
    background: #571700;
    margin-left: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
    color: #fff;
  }

  [href="/submit"] {
    order: 99;
    background: #005730;
    margin-left: 10px;
  }
}

.MuiDataGrid-cell[data-field="gene"] {
  font-style: italic;
}

.confidence {
  height: 100%;
  height: 52px;
  width: 220px;
  display: inline-block;
  padding: 0 18px;

  &.cf-high-confidence {
    background-color: rgba(#005730, 0.3);
  }
  &.cf-intermediate-confidence {
    background-color: rgba(#ffd700, 0.3);
  }
  &.cf-low-confidence {
    background-color: rgba(#c33400, 0.3);
  }
  &.cf-not-detected {
    background-color: rgba(#777, 0.3);
  }
}

.submit-form-messsage {
  max-width: 500px;

  .MuiFormControl-root {
    margin-bottom: 16px;
  }
}

.gc {
  margin-bottom: 0px;
}

.submit-form {
  .block {
    margin-bottom: 10px;
    .hint {
      span {
        color: #444;
        margin-right: 10px;
      }
    }
  }

  .MuiFormControlLabel-root {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;

    .MuiTypography-root {
      padding-top: 8px;
      max-width: 500px;
    }
  }

  .MuiButton-root {
    margin-right: 10px;
    min-width: 160px;
  }
}

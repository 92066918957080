.object-editor {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0em -0.5em 0.5em -0.5em;
  column-gap: 20px;

  &.read-only {
    display: flex;
    flex-direction: column;
    margin: 0 0 1.5em 0;
  }

  .input-block {
    width: auto;

    .MuiInputBase-root {
      min-width: 360px;
    }

    &.input-json {
      background: #fff6e3;
      border-radius: 4px;
      width: 100%;
      margin-top: 0.5em;

      h4 {
        margin-bottom: 0.3em;
        margin-left: 0.5em;
        margin-top: 0.5em;
      }
    }
  }

  .text-block {
    min-width: 340px;
    width: auto;
    display: flex;
    font-size: 16px;
    padding: 8px 0;
    padding-left: 12px;

    &.ACMG {
      background: #feffc8;
    }

    &:not(:last-child) {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    }

    .label {
      font-weight: bold;
      flex: 0 0 280px;
      margin-right: 5px;
      //text-transform: capitalize;
    }

    .value {
      //max-width: 300px;

      &.field-gene {
        font-style: italic;
      }
    }
  }
}
